<template>
  <section class="secure-check-footer">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <p><span>&copy; Copyright </span> <br /> Secure Check 2025</p>
          </div>
        </div>
      </div>
    </section>
</template>
