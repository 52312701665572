<script>
import { ref, onMounted, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import myService from "@/services/visitorcode";
import { useUserStore } from "@/stores/useUserStore";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { useGlobalStore } from "@/stores/globalStore";

export default {
  name: "UserAttendenceView",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const domain = ref(route?.params?.domain || "scb");
    const userStore = useUserStore();

    const { publicDir } = useGlobalStore();

    const userInfo = ref(JSON.parse(localStorage.getItem("userInfo")) || {});
    const companyId = ref(userInfo.value?.company_id || null);
    const roles = ref(userInfo.value?.roles || []);
    const currentRole = ref(roles.value.length > 0 ? roles.value[0] : null);
    const isAdmin = ref(currentRole.value === "ROLE_ADMIN");

    const allLogs = ref([]);
    const groupedLogs = ref({});
    const checkInRefs = ref({});
    const checkOutRefs = ref({});
    const checkInInstances = ref({});
    const checkOutInstances = ref({});

    const selectedImage = ref(null);

    const openImageModal = (imagePath) => {
      selectedImage.value = imagePath;
    };

    const closeImageModal = () => {
      selectedImage.value = null;
    };

    const groupLogs = () => {
      const grouped = {};

      const filteredLogs = isAdmin.value
        ? allLogs.value
        : allLogs.value.filter((log) => log.company_id === companyId.value);

      filteredLogs.forEach((log) => {
        if (!grouped[log.user_id]) {
          grouped[log.user_id] = {
            user_id: log.user_id,
            user_name: log.user_name,
            user_email: log.user_email,
            user_phone: log.user_phone,
            image: log.image,
            check_ins: [],
            check_outs: [],
          };
        }
        if (log.check_in_time) grouped[log.user_id].check_ins.push(log.check_in_time);
        if (log.check_out_time) grouped[log.user_id].check_outs.push(log.check_out_time);
      });

      groupedLogs.value = grouped;
    };

    const initCalendars = async () => {
      await nextTick();

      Object.keys(groupedLogs.value).forEach((userId) => {
        const user = groupedLogs.value[userId];

        checkInInstances.value[userId] = flatpickr(checkInRefs.value[userId], {
          mode: "multiple",
          enableTime: true,
          dateFormat: "Y-m-d H:i",
          defaultDate: user.check_ins.map((d) => new Date(d)),
          defaultViewDate: user.check_ins.length ? new Date(user.check_ins[0]) : undefined,
          onClose: (selectedDates) => {
            groupedLogs.value[userId].check_ins = selectedDates.map((d) => d.toISOString());
          },
        });

        checkOutInstances.value[userId] = flatpickr(checkOutRefs.value[userId], {
          mode: "multiple",
          enableTime: true,
          dateFormat: "Y-m-d H:i",
          defaultDate: user.check_outs.map((d) => new Date(d)),
          defaultViewDate: user.check_outs.length ? new Date(user.check_outs[0]) : undefined,
          onClose: (selectedDates) => {
            groupedLogs.value[userId].check_outs = selectedDates.map((d) => d.toISOString());
          },
        });
      });
    };

    const openCheckIn = (userId) => {
      checkInInstances.value[userId]?.open();
    };

    const openCheckOut = (userId) => {
      checkOutInstances.value[userId]?.open();
    };

    const fetchUserData = async () => {
      try {
        const response = await myService.fetchUserLog();
        allLogs.value = response;
        groupLogs();
        initCalendars();
      } catch (err) {
        console.error("Failed to fetch data", err);
      }
    };

    const formatDate = (datetime) => {
      if (!datetime) return "N/A";
      return new Date(datetime).toLocaleString([], {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    };

    const goToMenu = () => {
      router.push({ name: "Menu", params: { domain: domain.value } });
    };

    onMounted(() => {
      fetchUserData();
    });

    return {
      groupedLogs,
      checkInRefs,
      checkOutRefs,
      openCheckIn,
      openCheckOut,
      formatDate,
      goToMenu,
      userStore,
      publicDir,
      openImageModal,
      closeImageModal,
      selectedImage,
    };
  },
};
</script>

<template>
  <section class="secure-datatable-heading-back">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12">
          <div class="left-back">
            <router-link :to="{ name: 'Menu' }">
              <img src="@/assets/back-arrow-table.png" alt="back-arrow" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="container user_attendence">
    <h3 class="mt-3 mb-4 text-center">Lista de Check in / Check out por Usuario</h3>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>#</th>
          <th>User Name</th>
          <th>User Email</th>
          <th>Contact</th>
          <th>Image</th>
          <th>Check In</th>
          <th>Check Out</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="Object.keys(groupedLogs).length === 0">
          <td colspan="8" class="text-center">No data found</td>
        </tr>
        <tr
          v-for="(user, index) in Object.values(groupedLogs)"
          :key="user.user_id"
        >
          <td>{{ index + 1 }}</td>
          <td>{{ user.user_name }}</td>
          <td>{{ user.user_email }}</td>
          <td>{{ user.user_phone }}</td>
          <td>
            <img
              :src="`${publicDir}/user_document/${user.image}`"
              height="75"
              width="75"
              class="clickable-image"
              @click="openImageModal(`${publicDir}/user_document/${user.image}`)"
              alt="No img"
            />
          </td>

          <td>
            <img
              src="@/assets/calendar.png"
              alt="Open Check-In Calendar"
              width="24"
              style="cursor: pointer"
              @click="openCheckIn(user.user_id)"
            />
            <input
              type="text"
              class="calendar-hidden-input"
              :ref="(el) => (checkInRefs[user.user_id] = el)"
              readonly
            />
          </td>

          <td>
            <img
              src="@/assets/calendar.png"
              alt="Open Check-Out Calendar"
              width="24"
              style="cursor: pointer"
              @click="openCheckOut(user.user_id)"
            />
            <input
              type="text"
              class="calendar-hidden-input"
              :ref="(el) => (checkOutRefs[user.user_id] = el)"
              readonly
            />
          </td>

          <td>
            <router-link
              :to="{ name: 'UserQrCodeDisable', params: { id: user.user_id } }"
              >Edit</router-link
            >
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="selectedImage" class="modal-overlay" @click="closeImageModal">
        <button class="close-button" @click="closeImageModal">&times;</button>
        <div class="modal-content" @click.stop>
          <img :src="selectedImage" alt="Full Image" class="full-image" />
        </div>
      </div>
  </div>
</template>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  padding: 10px;
  text-align: left;
  vertical-align: middle;
}
th {
  background-color: #f4f4f4;
}

.calendar-hidden-input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  pointer-events: none;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.modal-content {
  background: #00000000;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.full-image {
  max-width: 90vw;
  max-height: 90vh;
  border-radius: 5px;
}
.clickable-image {
  cursor: pointer;
  margin: 20px 0;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgb(0, 0, 0);
  color: white;
  border: none;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  font-size: 30px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99999;
}
</style>
